.confirmation-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 35px;
}

@media screen and (max-width: 768px) {
  .confirmation-modal-buttons {
    flex-direction: column;
    flex-grow: 1;
    gap: 25px;
  }
}
