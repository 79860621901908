.loading-background-container {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  position: absolute;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  left: 50%;
  top: 40%;

  transform: translate(-50%, -40%) scale(1.2);
}

.lds-ripple div {
  position: absolute;

  border: 4px solid #7a7a7ae2;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
