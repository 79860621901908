.xci-nointeraction-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.xci-nointeraction-centered .xci-nointeraction-icon {
  font-size: 35vh;
  color: #00bcd4;
  padding: 3%;
}
.xci-nointeraction-centered .xci-nointeraction-text {
  text-align: left;
}

.xci-nointeraction-centered button {
  margin-top: 1%;
  background-color: #00bcd4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
