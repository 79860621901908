body {
  height: 100vh;
  width: 100vw;
}
.xci-login-button img {
  width: 24px;
  height: 24px;
  margin: 2%;
}
.xci-login-button span {
  font-size: 1em;
  font-weight: bold;
  color: #fff;
}
.xci-login-button {
  padding: 10px;
  margin: 2%;
}

.xci-login-screen {
  position: fixed;
  overflow: hidden;
  padding: 3%;
  width: 100%;
  height: 100vh;
  text-align: center;
  border-radius: 5px;
  background: linear-gradient(145deg,rgb(173,210,205) 22%,rgb(242,243,246) 40%);
}
.xci-login-screen * {
  color: #fff;
}
.xci-login-box {
  background-color: rgba(255, 255, 255, 0.533);
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 3%;
  left: 50%;
  top: 50%;
}
.login__logo {
  display: block;
  width: 24rem;
}

@media screen and (max-width: 768px) {
  .xci-login-box {
    width: 70%;
  }
}
