.xci-review-container-steps {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.xci-review-container-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.xci-review-empty-questions-alert {
  margin: 1rem;
  flex: 1 1 100%;
}

.xci-review-global-progress-rating {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
}

.dialog-main-content-container {
  width: 100%;
  height: 85%;
}

.loading-review-background-container {
  width: 100%;
  height: 100%;
  
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  position: relative;
}

@media screen and (max-width: 768px) {
  .xci-review-container-steps {
    flex-direction: column;
    flex: 1 1 45%;
  }
  .xci-review-container-buttons {
    flex-direction: column;
    flex-grow: 1;
  }
  .xci-review-container-buttons button {
    margin-bottom: 4%;
  }
}
